var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("z-card-modal", {
    attrs: { width: "600", title: _vm.modalTitle },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "cards-audience",
                staticStyle: {
                  display: "flex",
                  "align-items": "flex-start",
                  gap: "1rem",
                  padding: "1rem .5rem",
                  "flex-wrap": "wrap",
                  color: "333333",
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "selectable_card" },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "create_card_template" },
                              [
                                _c(
                                  "z-icon",
                                  { attrs: { large: "", color: "#2981D6" } },
                                  [_vm._v("mdi-pencil")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-title",
                              { staticClass: "create_card_template" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "create_card_template_title" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        "Criar novo template".toLocaleUpperCase()
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "create_card_template create_card_template_text",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Criar novo template de " +
                                      _vm._s(_vm.typeTemplate) +
                                      " do zero"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "selectable_card" },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "create_card_template" },
                              [
                                _c(
                                  "z-icon",
                                  { attrs: { large: "", color: "#2981D6" } },
                                  [_vm._v("mdi-folder")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-title",
                              { staticClass: "create_card_template" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "create_card_template_title" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        "Selecionar template".toLocaleUpperCase()
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "create_card_template create_card_template_text",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Selecionar um template já criado pelo grupo"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "pr-3", attrs: { justify: "end" } },
                  [
                    _c("z-btn", {
                      attrs: { small: "", rounded: "", text: "cancelar" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }