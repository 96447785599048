export default {
  name: "CreateTemplateModal",
  props: {
    showModal: {
      Type: Boolean,
      default: false
    },
    typeTemplate: {
      Type: String,
      default: ""
    }
  },
  computed: {
    modalTitle() {
      return `Selecionar ${this.typeTemplate}`;
    }
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('closeModalCreateTemplate')
    }
  }
}